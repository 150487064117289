import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  TextField, Autocomplete, CircularProgress, Popper,
  Box, List, ListItem, ListItemText, ListItemAvatar, ListItemButton
} from '@mui/material';

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: white;
    border-radius : .7rem;
  }
`

function SearchSuggest({ value, sx, array, onSelect, onChange, onKeyDown }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(async () => {
    if (!value || value === '') {
      setOpen(false)
    }
  });

  React.useEffect(async () => {
    let res = array
    if (!res) {
      await setOptions([]);
    } else {
      if (res.length === 0) {
        await setOptions([]);
      } else {
        await setOptions(array)
      }
    }
    setOpen(true)
  }, [array]);

  return (
    <div>
      <StyledTextField
        // {...params}
        id="text-search-mapschool"
        sx={{ ...sx, bg: 'white' }}
        className='w-full'
        value={value}
        onChange={onChange}
        autoFocus
        size="small"
        onFocus={(e) => {
          setAnchorEl(e.currentTarget);
          if (options.length > 0) {
            setOpen(true)
          }
        }}
        // onBlur={() => setOpen(false)}
        onKeyDown={onKeyDown}
        placeholder="ค้นหาสถานที่"
      />
      <Popper
        id={open ? 'simple-popper' : undefined}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        style={{
          width: sx.width,
          zIndex: 999,
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          borderRadius: 12
        }}>
        <Box style={{ maxHeight: '500px', overflowY: 'auto', backgroundColor: 'white' }}>
          <List>
            {
              options.length > 0 ? options.map((item) => (
                <ListItemButton
                  key={item?.name + item?.address + item?.lat + item?.lng}
                  onClick={(e) => {
                    setOpen(false)
                    onSelect(item?.name + item?.address)
                  }}>
                  <ListItemAvatar >
                    {
                      item?.type ? <img src={
                        item?.type === 'road' ?
                          `https://api.longdo.com/map/images/search/type-road.png` :
                          item?.type === "khet" ?
                            `https://api.longdo.com/map/images/search/type-area.png` :
                            `https://mmmap15.longdo.com/mmmap/images/icons/${item.icon}`} style={{ width: '2rem' }} /> : <></>
                    }
                  </ListItemAvatar>
                  <ListItemText key={item?.name + item?.address + item?.lat + item?.lng} primary={item?.name} secondary={item?.address} />
                </ListItemButton>
              )) :
                <ListItem>
                  <ListItemText className='text-gray-400' key={0} primary={`ไม่พบข้อมูลสถานที่ '${value}'`} />
                </ListItem>
            }
          </List>
        </Box>
      </Popper>
    </div>
  );
}

export default SearchSuggest

// import * as React from 'react';
// import { styled } from '@mui/material/styles';
// import {
//   TextField, Autocomplete, CircularProgress,
//   List, ListItem, ListItemText, ListItemAvatar
// } from '@mui/material';

// const StyledTextField = styled(TextField)`
//   .MuiInputBase-root {
//     background-color: white;
//     border-radius : 1rem;
//   }
// `

// function SearchSuggest({ value, sx, array, onSelect, onChange, onKeyDown }) {
//   const [open, setOpen] = React.useState(false);
//   const [options, setOptions] = React.useState([]);
//   // const loading = open && options.length === 0;

//   React.useEffect(async () => {
//     let res = array
//     console.log(array)
//     if (!res) {
//       await setOptions([]);
//     } else {
//       // if (res.length === 0) {
//       //   await setOptions([]);
//       // } else {
//       await setOptions(array)
//       // }
//     }
//     setOpen(true)
//   }, [array]);

//   return (
//     <Autocomplete
//       id="search-suggest"
//       // freeSolo
//       // disableClearable
//       // openOnFocus

//       // noOptionsText={'ไม่พบข้อมูล'}
//       sx={sx}
//       size="small"
//       value={value}
//       open={open}
//       onOpen={() => {
//         setOpen(true);
//       }}
//       onClose={() => {
//         setOpen(false);
//       }}
//       isOptionEqualToValue={(option, value) => options.name === options.name}
//       getOptionLabel={option => option.name ? option.name : option}
//       // onChange={}
//       options={options}
//       renderOption={(props, option, { selected }) => (
//         <List key={option?.name + option?.address + option?.lat + option?.lng} {...props}>
//           <ListItem onClick={(e) => onSelect(option?.name + option?.address)}>
//             {/* <ListItemAvatar >
//               {
//                 option?.type ? <img src={
//                   option?.type === 'road' ?
//                     `https://api.longdo.com/map/images/search/type-road.png` :
//                     option?.type === "khet" ?
//                       `https://api.longdo.com/map/images/search/type-area.png` :
//                       `https://mmmap15.longdo.com/mmmap/images/icons/${option.icon}`} style={{ width: '2rem' }} /> : <></>
//               }
//             </ListItemAvatar> */}
//             <ListItemText key={option?.name + option?.address + option?.lat + option?.lng} primary={option?.name} secondary={option?.address} />
//           </ListItem>
//         </List>
//       )}

//       // loading={loading}
//       renderInput={(params) => (
//         <StyledTextField
//           {...params}
//           id="text-search-mapschool"
//           sx={{ bg: 'white' }}
//           onChange={onChange}
//           onKeyDown={onKeyDown}
//           placeholder="ค้นหาสถานที่"
//           InputProps={{
//             ...params.InputProps,
//             endAdornment: (
//               <div>
//                 {/* {loading ? <CircularProgress color="inherit" size={15} /> : null} */}
//                 {params.InputProps.endAdornment}
//               </div>
//             ),
//           }}
//         />
//       )}
//     />
//   );
// }

// export default SearchSuggest
