import React, { useEffect, useState } from 'react';
import LongdoMap from '../longdomap'
import SearchSuggest from '../longdomap/SearchSuggest';
import Layoutas from '../components/Layout';
import RefDataDate from '../components/RefDataDate';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FormControlLabel, Checkbox } from '@mui/material';
import { Modal, Divider } from 'antd';
import { Table } from 'antd';
import api from '../util/api';

import marker_obec from '/images/marker/dot-obec.svg'
import marker_dla from '/images/marker/dot-dla.svg'
import marker_bpp from '/images/marker/dot-bpp.svg'
import marker_onab from '/images/marker/dot-onab.svg'
import marker_opec from '/images/marker/dot-opec.svg'
import marker_other from '/images/marker/dot-other.svg'

import './index.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className='p-3'>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Map = (props) => {
  const [initData, setInitData] = useState(null)
  let [filterNear, setFilterNear] = useState({
    jon100: false,
    jon80: false,
    nointernet: false,
    noelec: false
  })
  const [modalSchool, setModalSchool] = useState({
    visible: false
  });

  let [tabsearch, setTabSearch] = useState(0)
  let [searchtmp, setSearchtmp] = useState(null)
  let [onsearch, setOnsearch] = useState({ value: null })
  let [result, setResult] = useState([])
  let [options, setOptions] = useState([])
  let [schoolindistance, setSchoolindistance] = useState([])
  let [schoolList, setSchoolList] = useState([])

  let [searchschool, setSearchSchool] = useState(null)
  let [searchSchoolList, setSearchSchoolList] = useState([])

  useEffect(async () => {
    let dataSummaryAllyear = await api.getInitData("school");
    setInitData(dataSummaryAllyear.deepdata);
  }, [])

  useEffect(() => {
    if(initData){
      let result = initData
      if (filterNear.jon100) result = result.filter(item => (item.jon + item.exjon) !== 0 && (item.k + item.p + item.m + item.h) === (item.jon + item.exjon))
      if (filterNear.jon80) result = result.filter(item => ((item.jon + item.exjon) / (item.k + item.p + item.m + item.h)) > 0.8)
      if (filterNear.nointernet) result = result.filter(item => item.internet ? item.internet.includes('ไม่มีการเชื่อมต่ออินเทอร์เน็ต') : "")
      if (filterNear.noelec) result = result.filter(item => (!item.school_null_electricity && !item.school_null_electricity_service_area) && (!item.school_has_electricity && !item.school_has_electricity_service_area))
      setSchoolList(result)
    }
  }, [filterNear,initData]);

  const onChangeTabSearch = (event, newValue) => {
    if (newValue === 0) {
      setSearchSchoolList([])
    } else {
      setSearchtmp(null)
      setResult([])
      setOptions([])
      setSchoolindistance([])
    }
    setTabSearch(newValue)
  }

  const onSearch = (e) => {
    setOnsearch({ value: searchtmp })
  }

  const onSelect = (e) => {
    if (options.filter(item => item.name + item.address === e).length > 0) {
      setSearchtmp(options.filter(item => item.name + item.address === e)[0].name)
    }
    setResult(options.filter(item => item.name + item.address === e))
  }

  const onSearchResult = (result) => {
    setOptions(result.value)
  }

  const getGeolocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setResult([{ lat: position.coords.latitude, lon: position.coords.longitude }])
    });
  }

  const getSchoolInDistance = (arr) => {
    setSchoolindistance(arr)
  }

  const onSearchSchool = () => {
    searchSchoolList = initData.filter(item => item.hostname.includes(searchschool))
    setSearchSchoolList(searchSchoolList)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const ListItem = ({ data, color }) => {
    return (
      <div className='px-6 flex flex-col'>
        {data.map(item => {
          return (
            <div className='grid grid-cols-3 gap-4'>
              <div class="col-span-2 flex flex-row">
                <div className={`w-2 h-2 ${color} rounded-full my-auto mr-2`}></div>
                <span className='my-auto text-lg'>{item.name}</span>
              </div>
              <div class="col-span-1 text-lg text-right"><span>{`${item.value} ${item.unit ? item.unit : ''}`}</span></div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <Layoutas Role={['partner', 'eef', 'partner_province']} isHideFooter>
      <Modal
        width={'800px'}
        bodyStyle={{ borderRadius: 24 }}
        title={null}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={modalSchool.visible}
        centered={true}
        onCancel={() => {
          setModalSchool({ visible: false })
        }}
      >
        <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-2'>
          <div class="col-span-1 lg:col-span-2">
            {/* <div className='w-full h-full rounded-2xl flex flex-col shadow-xl bg-white px-2 py-3 mt-4'> */}
            <span className='text-2xl text-green-700 font-bold'>{modalSchool?.hostname}</span>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-2'>
              <div class="col-span-1 flex flex-col">
                <div><span className='text-lg'>สังกัด : &nbsp;</span><span className='text-md'>{modalSchool?.sungkud}</span></div>
                <div><span className='text-lg'>ระดับชั้นที่เปิดสอน : &nbsp;</span><span className='text-md'>{modalSchool?.lowest_classes ? `${modalSchool?.lowest_classes} - ${modalSchool?.highest_classes}` : 'ไม่ระบุ'}</span></div>
                <div><span className='text-lg'>ลักษณะภูมิประเทศ : &nbsp;</span><span className='text-md'>{modalSchool?.geographic_location ? `${modalSchool?.geographic_location}` : 'ไม่ระบุ'}</span></div>
                <div><span className='text-lg'>โรงเรียน TSQP : &nbsp;</span><span className='text-md'>{modalSchool?.tsqp ? `ใช่` : 'ไม่ใช่'}</span></div>
              </div>
              <div class="col-span-1 flex flex-col">
                <div><span className='text-lg'>ที่อยู่ : &nbsp;</span><span className='text-md'>{`ต.${modalSchool?.tumbon_name} อ.${modalSchool?.city_name} จ.${modalSchool?.province_name}`}</span></div>
                <div><span className='text-lg'>ขนาดโรงเรียน : &nbsp;</span><span className='text-md'>{modalSchool?.school_size ? `${modalSchool?.school_size}` : 'ไม่ระบุ'}</span></div>
                <div className='text-lg'>
                  <a href={`https://www.google.com/maps/search/?api=1&query=${modalSchool?.lat},${modalSchool?.lng}`} target="_blank">
                    ไปยัง Google map
                  </a>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>

          <div class="col-span-1">
            <div className='w-full h-full rounded-2xl flex flex-col shadow-xl bg-white mt-4'>
              <div className='w-full bg-cyan-600 rounded-t-2xl text-lg font-bold text-white text-center py-2'>ข้อมูลนักเรียน</div>
              <div className='flex flex-col text-center mt-2'>
                <span className='text-indigo-900 text-md font-bold'>จำนวนนักเรียนทั้งหมด</span>
                <span className='text-xl text-indigo-800 font-bold'>{(modalSchool?.k + modalSchool?.p + modalSchool?.m + modalSchool?.h).toLocaleString("en-US")}</span>
                <span className='text-indigo-900 text-md font-bold'>คน</span>
              </div>
              <div className='border border-grey-500 mx-6'></div>
              <ListItem data={[
                { name: 'อนุบาล', value: modalSchool?.k?.toLocaleString("en-US"), unit: 'คน' },
                { name: 'ประถมศึกษา', value: modalSchool?.p?.toLocaleString("en-US"), unit: 'คน' },
                { name: 'มัธยมศึกษาตอนต้น', value: modalSchool?.m?.toLocaleString("en-US"), unit: 'คน' },
                { name: 'มัธยมศึกษาตอนปลาย', value: modalSchool?.h?.toLocaleString("en-US"), unit: 'คน' },
              ]} color={'bg-[#19858D]'} />
            </div>
          </div>

          <div class="col-span-1">
            <div className='w-full h-full rounded-2xl flex flex-col shadow-xl bg-white mt-4'>
              <div className='w-full bg-green-600 rounded-t-2xl text-lg font-bold text-white text-center py-2'>ข้อมูลนักเรียนที่ได้รับทุน</div>
              <div className='flex flex-col text-center mt-2'>
                <span className='text-indigo-900 text-md font-bold'>จำนวนนักเรียนที่ได้รับทุน</span>
                <span className='text-xl text-indigo-800 font-bold'>{(modalSchool?.exjon + modalSchool?.krt + modalSchool?.vec + modalSchool?.tps).toLocaleString("en-US")}</span>
                <span className='text-indigo-900 text-md font-bold'>คน</span>
              </div>
              <div className='border border-grey-500 mx-6'></div>
              <ListItem data={[
                { name: 'ทุนเสมอภาค', value: modalSchool?.exjon?.toLocaleString("en-US"), unit: 'คน' },
                { name: 'ทุนครูรัก(ษ์)ถิ่น', value: modalSchool?.krt?.toLocaleString("en-US"), unit: 'คน' },
                { name: 'นวัตกรรมสายอาชีพ', value: modalSchool?.vec?.toLocaleString("en-US"), unit: 'คน' },
                { name: 'พระกนิษฐา', value: modalSchool?.tps?.toLocaleString("en-US"), unit: 'คน' },
              ]} color={'bg-emerald-600'} />
            </div>
          </div>

          <div class="col-span-1">
            <div className='w-full h-full rounded-2xl flex flex-col shadow-xl bg-white mt-4'>
              <div className='w-full bg-violet-500 rounded-t-2xl text-lg font-bold text-white text-center py-2'>ข้อมูลบุคลากร</div>
              <div className='flex flex-col text-center mt-2'>
                <span className='text-indigo-900 text-md font-bold'>จำนวนครูทั้งหมด</span>
                <span className='text-xl text-indigo-800 font-bold'>{modalSchool?.total_teacher?.toLocaleString("en-US")}</span>
                <span className='text-indigo-900 text-md font-bold'>คน</span>
              </div>
              <div className='border border-grey-500 mx-6'></div>
              <ListItem data={[
                { name: 'ชำนาญการพิเศษ', value: modalSchool?.academic_standing_specialization?.toLocaleString("en-US"), unit: 'คน' },
                { name: 'ชำนาญการ', value: modalSchool?.academic_standing_skilled?.toLocaleString("en-US"), unit: 'คน' },
                { name: 'เชี่ยวชาญ', value: modalSchool?.academic_standing_specialize?.toLocaleString("en-US"), unit: 'คน' },
                { name: 'อื่นๆ', value: modalSchool?.academic_standing_other?.toLocaleString("en-US"), unit: 'คน' },
                { name: 'ไม่มีวิทยะฐานะ', value: modalSchool?.academic_standing_no?.toLocaleString("en-US"), unit: 'คน' },
              ]} color={'bg-purple-600'} />
            </div>
          </div>

          <div class="col-span-1">
            <div className='w-full h-full rounded-2xl flex flex-col shadow-xl bg-white mt-4'>
              <div className='w-full bg-orange-500 rounded-t-2xl text-lg font-bold text-white text-center py-2'>ข้อมูลทรัพยากร</div>
              <div className='border border-grey-500 mx-6'></div>
              <div className='px-6 flex flex-col'>
                {
                  [{ name: 'ไฟฟ้า', value: (modalSchool?.school_null_electricity_service_area ? 'ไม่ระบุ' : (modalSchool?.school_has_electricity_service_area ? 'อยู่ในเขตไฟฟ้านครหลวง' : 'ไม่อยู่ในเขตไฟฟ้านครหลวง')), unit: '' },
                  { name: 'แหล่งน้ำ', value: modalSchool?.water_source, unit: '' },
                  { name: 'การเข้าถึง Internet', value: modalSchool?.internet, unit: '' },
                  {
                    name: 'คอมพิวเตอร์',
                    value: (modalSchool?.computer_for_learning + modalSchool?.computer_for_manage) === 0 ? 'N/A' : (modalSchool?.computer_for_learning + modalSchool?.computer_for_manage),
                    unit: 'เครื่อง'
                  },
                  ].map(item => {
                    return (
                      <div className='py-1'>
                        <div className='flex flex-row'>
                          <span className='my-auto text-lg'>{`${item.name} : ${item.value} ${item.unit ? item.unit : ''}`}</span>
                        </div>
                        <div className='border border-grey-500 mx-2'></div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className='relative lg:overflow-hidden'>
        <div className='w-[100vw] h-[100vh]'>

          <div className='relative w-full h-3/4 lg:w-full lg:h-full'>
            <LongdoMap
              id="longdo-map"
              tabsearch={tabsearch}
              searchtmp={searchtmp}
              onsearch={onsearch}
              onSearchResult={onSearchResult}
              onClickMarker={(record) => setModalSchool({ visible: true, ...record })}
              stopBounceMarker={!modalSchool.visible}
              marker={result}
              schoolmarker={schoolList}
              schoolsearhmarker={searchSchoolList}
              getSchoolInDistance={getSchoolInDistance} />

            <div className={`absolute bottom-[10px] left-2 z-1}`}>
              <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                <span className='font-bold center mx-1 mb-2'>ประเภทสถานศึกษาตามสังกัด</span>
                <div class="flex flex-row">
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_obec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                    <span className='center m-auto'>สพฐ.</span>
                  </div>
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_dla} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                    <span className='center m-auto'>อปท.</span>
                  </div>
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_bpp} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                    <span className='center m-auto'>ตชด.</span>
                  </div>
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_onab} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                    <span className='center m-auto'>พศ.</span>
                  </div>
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_opec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                    <span className='center m-auto'>สช.</span>
                  </div>
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_other} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                    <span className='center m-auto'>อื่นๆ</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`relative lg:absolute lg:top-[90px] lg:right-2 lg:z-2}`}>
            <div className='w-full lg:w-[600px] xl:w-[800px] lg:h-full rounded-2xl flex flex-col shadow-xl bg-white px-2 py-3'>
              <Tabs value={tabsearch} onChange={onChangeTabSearch} aria-label="basic tabs example" centered>
                <Tab label={<span className='text-lg font-bold'>ค้นหาจากจุดสนใจ</span>} {...a11yProps(0)} />
                <Tab label={<span className='text-lg font-bold'>ค้นหาโรงเรียน</span>} {...a11yProps(1)} />
              </Tabs>
              <div className='border border-grey-500 mx-4'></div>
              <TabPanel value={tabsearch} index={0}>
                <div className='flex flex-col'>
                  <span className='text-lg font-bold text-[#093F72]'>ค้นหาด้วยชื่อสถานที่</span>
                  <div className='h-full rounded-lg flex flex-col lg:flex-row shadow-xl bg-[#7ed696] px-2 py-3'>
                    <SearchSuggest
                      sx={{ bg: 'white' }}
                      array={options}
                      value={searchtmp}
                      onChange={(e) => setSearchtmp(e.target.value)}
                      onSelect={onSelect}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onSearch()
                        }
                      }} />
                    {/* <input class="w-[500px] shadow appearance-none border rounded-[0.7rem] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="username" type="text" placeholder="ค้นหาสถานที่" onChange={(event) => setSearchtmp(event.target.value)} /> */}
                    <button className='bg-[#5c80ff] hover:bg-blue-700 text-white py-1 px-4 my-1 lg:my-0 lg:mx-2 rounded-2xl' onClick={onSearch}>ค้นหา</button>
                    <button className='bg-[#5c80ff] hover:bg-blue-700 text-white py-1 px-4 my-1 lg:my-0 rounded-2xl' onClick={getGeolocation}>ดึงพิกัดจากตำแหน่งที่ตั้ง</button>
                  </div>

                  <div>
                    <span className='mt-2 text-lg font-bold text-[#093F72]'>กรองข้อมูลเพิ่มเติม</span>
                    <div className='flex flex-col lg:flex-row my-2'>
                      <FormControlLabel
                        onChange={() => setFilterNear({ ...filterNear, jon100: !filterNear.jon100 })}
                        value={filterNear.jon100}
                        control={<Checkbox sx={{ py: 0, pl: 1, pr: 1 }} size={"small"} />}
                        label={<span className='text-sm'>รร.ที่มี นร.ยากจน 100%</span>}
                      />
                      <FormControlLabel
                        onChange={() => setFilterNear({ ...filterNear, jon80: !filterNear.jon80 })}
                        value={filterNear.jon80}
                        control={<Checkbox sx={{ py: 0, pl: 1, pr: 1 }} size={"small"} />}
                        label={<span className='text-sm'>รร.ที่มี นร.ยากจนมากกว่า 80%</span>}
                      />
                      <FormControlLabel
                        onChange={() => setFilterNear({ ...filterNear, nointernet: !filterNear.nointernet })}
                        value={filterNear.nointernet}
                        control={<Checkbox sx={{ py: 0, pl: 1, pr: 1 }} size={"small"} />}
                        label={<span className='text-sm'>รร.ที่ขาดแคลนอินเตอร์เน็ต</span>}
                      />
                      <FormControlLabel
                        onChange={() => setFilterNear({ ...filterNear, noelec: !filterNear.noelec })}
                        value={filterNear.noelec}
                        control={<Checkbox sx={{ py: 0, pl: 1, pr: 1 }} size={"small"} />}
                        label={<span className='text-sm'>รร.ที่ไม่มีไฟฟ้าใช้</span>}
                      />
                      {/* <button
                        className={`${filterNear.jon100 ? 'bg-green-200 text-green-600 border-2 border-green-600' : 'bg-white text-gray-600 border-2 border-gray-400'} py-1 px-2 mr-2 rounded-md`}
                        onClick={() => setFilterNear({ ...filterNear, jon100: !filterNear.jon100 })}>
                        <FormControlLabel
                          value={filterNear.jon100}
                          control={<Checkbox sx={{ py: 0, pl: 1, pr: 1 }} size={"small"} />}
                          label={<span className='text-sm'>รร.ที่มี นร.ยากจน 100 %</span>}
                        />
                      </button>
                      <button
                        className={`${filterNear.jon80 ? 'bg-green-200 text-green-600 border-2 border-green-600' : 'bg-white text-gray-600 border-2 border-gray-400'} py-1 px-2 mr-2 rounded-md`}
                        onClick={() => setFilterNear({ ...filterNear, jon80: !filterNear.jon80 })}>
                        <FormControlLabel
                          value={filterNear.jon80}
                          control={<Checkbox sx={{ py: 0, pl: 1, pr: 1 }} size={"small"} />}
                          label={<span className='text-sm'>รร.ที่มี นร.ยากจนมากกว่า 80 %</span>}
                        />
                      </button>
                      <button
                        className={`${filterNear.nointernet ? 'bg-green-200 text-green-600 border-2 border-green-600' : 'bg-white text-gray-600 border-2 border-gray-400'} py-1 px-2 mr-2 rounded-md`}
                        onClick={() => setFilterNear({ ...filterNear, nointernet: !filterNear.nointernet })}>
                        <FormControlLabel
                          value={filterNear.nointernet}
                          control={<Checkbox sx={{ py: 0, pl: 1, pr: 1 }} size={"small"} />}
                          label={<span className='text-sm'>รร.ที่ขาดแคลนอินเตอร์เน็ต</span>}
                        />
                      </button>
                      <button
                        className={`${filterNear.noelec ? 'bg-green-200 text-green-600 border-2 border-green-600' : 'bg-white text-gray-600 border-2 border-gray-400'} py-1 px-2 mr-2 rounded-md`}
                        onClick={() => setFilterNear({ ...filterNear, noelec: !filterNear.noelec })}>
                        <FormControlLabel
                          value={filterNear.noelec}
                          control={<Checkbox sx={{ py: 0, pl: 1, pr: 1 }} size={"small"} />}
                          label={<span className='text-sm'>รร.ที่ไม่มีไฟฟ้าใช้</span>}
                        />
                      </button> */}
                      {/* <button
                        className={`${filterNear.jon100 ? 'bg-[#37ca72]' : 'bg-neutral-400'} ${filterNear.jon100 ? 'hover:bg-[#2c9958]' : 'hover:bg-[#37ca72]'} text-white py-1 px-2 mr-2 rounded-lg`}
                        onClick={() => setFilterNear({ ...filterNear, jon100: !filterNear.jon100 })}>
                        รร. ที่มี นร. ยากจน 100 %
                      </button>
                      <button
                        className={`${filterNear.jon80 ? 'bg-[#37ca72]' : 'bg-neutral-400'} ${filterNear.jon80 ? 'hover:bg-[#2c9958]' : 'hover:bg-[#37ca72]'} text-white py-1 px-2 mr-2 rounded-lg`}
                        onClick={() => setFilterNear({ ...filterNear, jon80: !filterNear.jon80 })}>
                        รร. ที่มี นร. ยากจนมากกว่า 80 %</button>
                      <button
                        className={`${filterNear.nointernet ? 'bg-[#37ca72]' : 'bg-neutral-400'} ${filterNear.nointernet ? 'hover:bg-[#2c9958]' : 'hover:bg-[#37ca72]'} text-white py-1 px-2 mr-2 rounded-lg`}
                        onClick={() => setFilterNear({ ...filterNear, nointernet: !filterNear.nointernet })}>
                        รร. ที่ขาดแคลนอินเตอร์เน็ต</button>
                      <button
                        className={`${filterNear.noelec ? 'bg-[#37ca72]' : 'bg-neutral-400'} ${filterNear.noelec ? 'hover:bg-[#2c9958]' : 'hover:bg-[#37ca72]'} text-white py-1 px-2 mr-2 rounded-lg`}
                        onClick={() => setFilterNear({ ...filterNear, noelec: !filterNear.noelec })}>
                        รร. ที่ไม่มีไฟฟ้าใช้</button> */}
                    </div>
                  </div>
                  <div className='flex flex-row'>
                    <span className='text-base text-red-500'>(โรงเรียนยากจน 100% : โรงเรียนที่มีนักเรียนยากจนและยากจนพิเศษทั้งโรงเรียน)</span>
                  </div>

                </div>
              </TabPanel>
              <TabPanel value={tabsearch} index={1}>
                <div className='flex flex-col'>
                  <span className='text-lg font-bold text-[#093F72]'>ค้นหาด้วยชื่อโรงเรียน</span>
                  <div className='h-full rounded-lg flex flex-row shadow-xl bg-[#7ed696] px-2 py-3'>
                    <input class="shadow appearance-none border rounded-[0.7rem] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="username"
                      type="text"
                      value={searchschool}
                      placeholder="ค้นหาโรงเรียน"
                      onChange={(e) => setSearchSchool(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onSearchSchool()
                        }
                      }} />
                    <button className='bg-[#5c80ff] hover:bg-blue-700 text-white py-1 px-4 mx-2 rounded-2xl' onClick={onSearchSchool}>ค้นหา</button>
                  </div>
                </div>
              </TabPanel>
            </div>

            {/* {schoolindistance.length > 0 && tabsearch === 0 ? */}
            <div className='w-full lg:w-[600px] xl:w-[800px]  lg:max-h-[650px] rounded-2xl flex flex-col shadow-xl bg-white px-2 py-3 mt-4'>
              <TabPanel value={tabsearch} index={0}>
                <div className='flex flex-col'>
                  <span className='text-lg font-bold text-[#093F72]'>ผลการค้นหา</span>
                  <span className='text-lg font-bold'>ผลการค้นหาโรงเรียนในรัศมี 20 กิโลเมตร โดยรอบบริเวณจุดสนใจ พบ&nbsp;
                    <span className='text-[#01B65F]'>{schoolindistance.length.toLocaleString("en-US")}</span> โรงเรียน
                  </span>
                  <Table
                    size="small"
                    bordered
                    style={{ width: '100%', overflowY: 'auto' }}
                    // style={{ width: '100%' }}
                    scroll={{ x: 1200, y: '28vh' }}
                    // pagination={{ pageSize: 8 }}
                    // scroll={{ y: '30vh' }}
                    columns={[
                      {
                        title: 'ชื่อโรงเรียน',
                        dataIndex: 'hostname',
                        key: 'hostname',
                        // ellipsis: true,
                        align: 'center',

                        render(text, record) {
                          return {
                            children: <a onClick={() => setModalSchool({ visible: true, ...record })}>{text}</a>
                          };
                        },
                        sorter: (a, b) => a.hostname.localeCompare(b.hostname),
                      },
                      {
                        title: 'สังกัด',
                        dataIndex: 'sungkud',
                        key: 'sungkud',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div>{text}</div>
                          };
                        },
                        sorter: (a, b) => a.sungkud.localeCompare(b.sungkud),
                      },
                      {
                        title: 'ขนาด',
                        dataIndex: 'school_size',
                        key: 'school_size',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div>{text}</div>
                          };
                        },
                        sorter: (a, b) => a.school_size.localeCompare(b.school_size),
                      },
                      {
                        title: 'จังหวัด',
                        dataIndex: 'province_name',
                        key: 'province_name',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div>{text}</div>
                          };
                        },
                        sorter: (a, b) => a.province_name.localeCompare(b.province_name),
                      },
                      {
                        title: 'อำเภอ',
                        dataIndex: 'city_name',
                        key: 'city_name',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div>{text}</div>
                          };
                        },
                        sorter: (a, b) => a.city_name.localeCompare(b.city_name),
                      },
                      {
                        title: 'ตำบล',
                        dataIndex: 'tumbon_name',
                        key: 'tumbon_name',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div>{text}</div>
                          };
                        },
                        sorter: (a, b) => a.tumbon_name.localeCompare(b.tumbon_name),
                      },
                      {
                        title: 'ระยะห่างจากจุดค้นหา (Km)',
                        dataIndex: 'distance',
                        key: 'distance',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div>{text}</div>
                          };
                        },
                        sorter: (a, b) => a.distance - b.distance,
                      },
                    ]}
                    dataSource={schoolindistance}
                  >

                  </Table>
                  <RefDataDate
                    title={
                      'ข้อมูลสถานศึกษาใน 5 สังกัด ได้แก่ สพฐ. อปท. ตชด. พศ. และ สช. ปีการศึกษา 2565'
                    }
                    subjectTitle={"*ประมวลผล ณ เดือนสิงหาคม 2567"}
                  />
                </div>
              </TabPanel>
              <TabPanel value={tabsearch} index={1}>
                <div className='flex flex-col'>
                  <span className='text-lg font-bold'>ผลการค้นหา พบ&nbsp;
                    <span className='text-[#01B65F]'>{searchSchoolList.length.toLocaleString("en-US")}</span> โรงเรียน
                  </span>
                  <Table
                    size="small"
                    bordered
                    style={{ width: '100%' }}
                    // pagination={{ pageSize: 8 }}
                    scroll={{ y: '50vh' }}
                    columns={[
                      {
                        title: 'ชื่อโรงเรียน',
                        dataIndex: 'hostname',
                        key: 'hostname',
                        // ellipsis: true,
                        align: 'center',

                        render(text, record) {
                          return {
                            children: <div>{text}</div>
                          };
                        },
                        sorter: (a, b) => a.hostname.localeCompare(b.hostname),
                      },
                      {
                        title: 'สังกัด',
                        dataIndex: 'sungkud',
                        key: 'sungkud',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div>{text}</div>
                          };
                        },
                        sorter: (a, b) => a.sungkud.localeCompare(b.sungkud),
                      },
                      {
                        title: 'ขนาด',
                        dataIndex: 'school_size',
                        key: 'school_size',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div>{text}</div>
                          };
                        },
                        sorter: (a, b) => a.school_size.localeCompare(b.school_size),
                      },
                      {
                        title: 'จังหวัด',
                        dataIndex: 'province_name',
                        key: 'province_name',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div>{text}</div>
                          };
                        },
                        sorter: (a, b) => a.province_name.localeCompare(b.province_name),
                      },
                      {
                        title: 'อำเภอ',
                        dataIndex: 'city_name',
                        key: 'city_name',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div>{text}</div>
                          };
                        },
                        sorter: (a, b) => a.city_name.localeCompare(b.city_name),
                      },
                      {
                        title: 'ตำบล',
                        dataIndex: 'tumbon_name',
                        key: 'tumbon_name',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <div>{text}</div>
                          };
                        },
                        sorter: (a, b) => a.tumbon_name.localeCompare(b.tumbon_name),
                      },
                      {
                        title: '',
                        dataIndex: 'goSchool',
                        key: 'goSchool',
                        align: 'center',
                        render(text, record) {
                          return {
                            children: <a onClick={() => setModalSchool({ visible: true, ...record })}>{'สำรวจข้อมูล'}</a>
                          };
                        },
                      },
                    ]}
                    dataSource={searchSchoolList}
                  >
                  </Table>
                  <RefDataDate
                    title={
                      'ข้อมูลสถานศึกษาใน 5 สังกัด ได้แก่ สพฐ. อปท. ตชด. พศ. และ สช. ปีการศึกษา 2565'
                    }
                    subjectTitle={"*ประมวลผล ณ เดือนสิงหาคม 2567"}
                  />
                </div>
              </TabPanel>
            </div>
            {/* : <></>
            } */}

          </div>

        </div>
      </div>
    </Layoutas>
  )
}

export default Map

